// -------------------------------------------------------------------
// :: GRID SYSTEM
// -------------------------------------------------------------------
// The grid system should be wrapped inside a div
// with classes: .container and .inline-list

@use 'sass:math';

$grid-gutter: 1rem !default;
$grid-gutter-small: 0.5rem !default;
$grid-gutter-row: $grid-gutter !default;

$_grid-col-2: 100% * 0.5;
$_grid-col-3: math.div(100%, 3);
$_grid-col-4: 100% * 0.25;
$_grid-col-5: 100% * 0.2;

.u-grid {
	@include clearfloat();
}

[class*='col-'] {
	float: left;
	width: 100%;
	vertical-align: top;
}

.col-1-2 {
	@include respond-at($tablet) {
		width: $_grid-col-2;
	}
}
.col-1-3 {
	@include respond-at($tablet) {
		width: $_grid-col-2;
	}
	@include respond-at($desktop) {
		width: $_grid-col-3;
	}
}
.col-2-3 {
	@include respond-at($tablet) {
		width: 2 * $_grid-col-3;
	}
}
.col-1-4 {
	@include respond-at($tablet) {
		width: $_grid-col-2;
	}
	@include respond-at($desktop) {
		width: $_grid-col-4;
	}
}
.col-3-4 {
	@include respond-at($tablet) {
		width: $_grid-col-2;
	}
	@include respond-at($desktop) {
		width: 3 * $_grid-col-4;
	}
}
.col-1-5 {
	width: $_grid-col-2;
	@include respond-at($tablet) {
		width: $_grid-col-5;
	}
}

.u-grid.u-grid--not-responsive {
	.col-1-2 {
		width: $_grid-col-2;
	}
	.col-1-3 {
		width: $_grid-col-3;
	}
	.col-2-3 {
		width: 2 * $_grid-col-3;
	}
	.col-1-4 {
		width: $_grid-col-4;
	}
	.col-3-4 {
		width: 3 * $_grid-col-4;
	}
	.col-1-5 {
		width: $_grid-col-5;
	}
	.col-4-5 {
		width: 4 * $_grid-col-5;
	}
}

// Extended grid system
// for use in page layouts

.u-grid.has-default-margins {
	width: calc(100% + #{$grid-gutter});
	margin-left: -$grid-gutter * 0.5;
	margin-top: -$grid-gutter * 0.5;

	.col-1-2,
	.col-1-3,
	.col-2-3,
	.col-3-4,
	.col-1-5,
	.col-4-5,
	.col-1-4 {
		margin: #{$grid-gutter-row * 0.5} #{$grid-gutter * 0.5};
		width: calc(100% - #{$grid-gutter});
	}

	.col-1-2 {
		@include respond-at($tablet) {
			width: calc(#{$_grid-col-2} - #{$grid-gutter});
		}
	}

	.col-1-3 {
		&:not(.wide-content) {
			@include respond-at($tablet) {
				width: calc(#{$_grid-col-2} - #{$grid-gutter});
			}
			@include respond-at($desktop) {
				width: calc(#{$_grid-col-3} - #{$grid-gutter});
			}
		}
		@include respond-at($desktop) {
			width: calc(#{$_grid-col-3} - #{$grid-gutter});
		}
	}

	.col-2-3 {
		@include respond-at($tablet) {
			width: calc(#{2 * $_grid-col-3} - #{$grid-gutter});
		}
	}

	.col-3-4 {
		&:not(.wide-content) {
			@include respond-at($tablet) {
				width: calc(#{$_grid-col-2} - #{$grid-gutter});
			}
		}
		@include respond-at($desktop) {
			width: calc(#{3 * $_grid-col-4} - #{$grid-gutter});
		}
	}

	.col-1-5 {
		width: calc(100% - #{$grid-gutter});

		@include respond-at($desktop-wide) {
			width: calc(#{$_grid-col-5} - #{$grid-gutter});
		}
	}

	.col-4-5 {
		width: calc(100% - #{$grid-gutter});

		@include respond-at($desktop-wide) {
			width: calc(#{4 * $_grid-col-5} - #{$grid-gutter});
		}
	}

	.col-1-4 {
		&:not(.wide-content) {
			@include respond-at($tablet) {
				width: calc(#{$_grid-col-2} - #{$grid-gutter});
			}
		}
		@include respond-at($desktop) {
			width: calc(#{$_grid-col-4} - #{$grid-gutter});
		}
	}
}

.u-grid.has-small-margins {
	width: calc(100% + #{$grid-gutter-small});
	margin-left: -$grid-gutter-small * 0.5;
	margin-top: -$grid-gutter-small * 0.5;

	.col-1-3 {
		margin: #{$grid-gutter-small * 0.5};
		width: calc(100% - #{$grid-gutter-small});
	}

	.col-1-3 {
		&:not(.wide-content) {
			@include respond-at($tablet) {
				width: calc(#{$_grid-col-2} - #{$grid-gutter-small});
			}
			@include respond-at($desktop) {
				width: calc(#{$_grid-col-3} - #{$grid-gutter-small});
			}
		}
		@include respond-at($desktop) {
			width: calc(#{$_grid-col-3} - #{$grid-gutter-small});
		}
	}
}

.u-grid.has-default-margins.u-grid--not-responsive {
	[class*='col-'] {
		margin: #{$grid-gutter-row * 0.5} #{$grid-gutter * 0.5};
		width: calc(100% - #{$grid-gutter});
	}

	.col-1-2 {
		width: calc(#{$_grid-col-2} - #{$grid-gutter});
	}

	.col-1-3 {
		width: calc(#{$_grid-col-3} - #{$grid-gutter});
	}

	.col-2-3 {
		width: calc(#{2 * $_grid-col-3} - #{$grid-gutter});
	}

	.col-1-4 {
		width: calc(#{$_grid-col-4} - #{$grid-gutter});
	}

	.col-3-4 {
		width: calc(#{3 * $_grid-col-4} - #{$grid-gutter});
	}

	.col-1-5 {
		width: calc(#{$_grid-col-5} - #{$grid-gutter});
	}
}

// Squared tiles with the .squared class

.squared {
	&[class*='col-'] {
		padding-bottom: $_grid-col-2; //not square on mobile!!
	}

	&.col-1-3 {
		@include respond-at($desktop) {
			padding-bottom: $_grid-col-3;
		}
	}

	&.col-1-4 {
		@include respond-at($desktop) {
			padding-bottom: $_grid-col-4;
		}
	}

	&.col-1-5 {
		@include respond-at($desktop) {
			padding-bottom: $_grid-col-5;
		}
	}
}

@media all and (min-width: $desktop) {
	.u-grid-diagonal {
		.col-1-3 + .col-1-3 {
			margin-top: 6rem;
		}
		.col-1-3 + .col-1-3 + .col-1-3 {
			margin-top: 12rem;
		}
	}
}
