// -------------------------------------------------------------------
// :: INFO CLASSES
// -------------------------------------------------------------------
.c-info {
	padding: 0.5em 1em 0.5em 0.5em;
	background-color: $grey-lighter;
	margin: 0.5rem 0;

	small[class*='icon-'] {
		padding-left: 2em;
	}

	small[class*='icon-']:before {
		position: absolute;
		left: 0;
		font-size: $font-size-large;
		color: $grey;
		top: 50%;
		transform: translateY(-50%);
	}
}
