// -------------------------------------------------------------------
// :: Loaders
// -------------------------------------------------------------------

// FULLSCREEN PRELOADER

.c-preloader {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	display: table;

	> span {
		display: block;

		@include respond-at($tablet) {
			display: table-cell;
			vertical-align: middle;
		}
	}
}

.c-preloader__logo {
	color: $primary-color-dark;
	font-size: 160px;
	line-height: 0;
	animation: breathe 3s infinite;
}

.c-preloader__logo-image {
	animation: breathe 3s infinite;
}

.c-preloader__info {
	font-size: $font-size-large;
	color: $font-color-medium;
	padding: 0 1rem;
}
