// -------------------------------------------------------------------
// :: ICON FONT
// -------------------------------------------------------------------
// Created with the 'gulp iconfont'-task, the source-template
// of this file can be found in fonts/icon-sources/_template.scss

@include get-font('icons', '../../fonts/icons');

%___FLOW-icon {
	font-family: 'icons';
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	vertical-align: middle;
	line-height: 1;
	speak: none;

	// Improve font-rendering
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

// -------------------------------------------------------------------
// :: CLASSES
// -------------------------------------------------------------------
// Note: Use single quotes ('') for content in pseudo-selectors
// as double quotes ("") are not supported in <IE9

// BEFORE
.icon-alert:before {
	@extend %___FLOW-icon;
	content: '\EA01';
}
.icon-arrow-big-right:before {
	@extend %___FLOW-icon;
	content: '\EA02';
}
.icon-arrow-down:before {
	@extend %___FLOW-icon;
	content: '\EA03';
}
.icon-arrow-left:before {
	@extend %___FLOW-icon;
	content: '\EA04';
}
.icon-arrow-right:before {
	@extend %___FLOW-icon;
	content: '\EA05';
}
.icon-arrow-up:before {
	@extend %___FLOW-icon;
	content: '\EA06';
}
.icon-attachment:before {
	@extend %___FLOW-icon;
	content: '\EA07';
}
.icon-book:before {
	@extend %___FLOW-icon;
	content: '\EA08';
}
.icon-bookmark:before {
	@extend %___FLOW-icon;
	content: '\EA09';
}
.icon-books:before {
	@extend %___FLOW-icon;
	content: '\EA0A';
}
.icon-checkmark-roundel:before {
	@extend %___FLOW-icon;
	content: '\EA0B';
}
.icon-checkmark:before {
	@extend %___FLOW-icon;
	content: '\EA0C';
}
.icon-close:before {
	@extend %___FLOW-icon;
	content: '\EA0D';
}
.icon-company:before {
	@extend %___FLOW-icon;
	content: '\EA0E';
}
.icon-sanctions:before {
	@extend %___FLOW-icon;
	content: '\EA11';
}
.icon-crosshair:before {
	@extend %___FLOW-icon;
	content: '\EA0F';
}
.icon-dashboard:before {
	@extend %___FLOW-icon;
	content: '\EA10';
}
.icon-document:before {
	@extend %___FLOW-icon;
	content: '\EA11';
}
.icon-ebox:before {
	@extend %___FLOW-icon;
	content: '\EA12';
}
.icon-edit:before {
	@extend %___FLOW-icon;
	content: '\EA13';
}
.icon-envelop:before {
	@extend %___FLOW-icon;
	content: '\EA14';
}
.icon-envelope-check:before {
	@extend %___FLOW-icon;
	content: '\EA15';
}
.icon-filter:before {
	@extend %___FLOW-icon;
	content: '\EA16';
}
.icon-filter_placeholder:before {
	@extend %___FLOW-icon;
	content: '\EA17';
}
.icon-flag:before {
	@extend %___FLOW-icon;
	content: '\EA18';
}
.icon-flanders:before {
	@extend %___FLOW-icon;
	content: '\EA19';
}
.icon-grid:before {
	@extend %___FLOW-icon;
	content: '\EA1A';
}
.icon-imac:before {
	@extend %___FLOW-icon;
	content: '\EA1B';
}
.icon-info-icon:before {
	@extend %___FLOW-icon;
	content: '\EA1C';
}
.icon-info:before {
	@extend %___FLOW-icon;
	content: '\EA1D';
}
.icon-inloggen:before {
	@extend %___FLOW-icon;
	content: '\EA1E';
}
.icon-layers:before {
	@extend %___FLOW-icon;
	content: '\EA1F';
}
.icon-lightbulb:before {
	@extend %___FLOW-icon;
	content: '\EA20';
}
.icon-list:before {
	@extend %___FLOW-icon;
	content: '\EA21';
}
.icon-message:before {
	@extend %___FLOW-icon;
	content: '\EA22';
}
.icon-messages:before {
	@extend %___FLOW-icon;
	content: '\EA23';
}
.icon-more:before {
	@extend %___FLOW-icon;
	content: '\EA24';
}
.icon-note-like:before {
	@extend %___FLOW-icon;
	content: '\EA25';
}
.icon-note:before {
	@extend %___FLOW-icon;
	content: '\EA26';
}
.icon-notepad:before {
	@extend %___FLOW-icon;
	content: '\EA27';
}
.icon-notifications:before {
	@extend %___FLOW-icon;
	content: '\EA28';
}
.icon-open-externally:before {
	@extend %___FLOW-icon;
	content: '\EA29';
}
.icon-page:before {
	@extend %___FLOW-icon;
	content: '\EA2A';
}
.icon-phone:before {
	@extend %___FLOW-icon;
	content: '\EA2B';
}
.icon-photo:before {
	@extend %___FLOW-icon;
	content: '\EA2C';
}
.icon-plus-circle:before {
	@extend %___FLOW-icon;
	content: '\EA2D';
}
.icon-plus:before {
	@extend %___FLOW-icon;
	content: '\EA2E';
}
.icon-print:before {
	@extend %___FLOW-icon;
	content: '\EA2F';
}
.icon-profile-settings:before {
	@extend %___FLOW-icon;
	content: '\EA30';
}
.icon-profile:before {
	@extend %___FLOW-icon;
	content: '\EA31';
}
.icon-search:before {
	@extend %___FLOW-icon;
	content: '\EA32';
}
.icon-settings-hollow:before {
	@extend %___FLOW-icon;
	content: '\EA33';
}
.icon-settings:before {
	@extend %___FLOW-icon;
	content: '\EA34';
}
.icon-small-arrow-down:before {
	@extend %___FLOW-icon;
	content: '\EA35';
}
.icon-small-arrow-left:before {
	@extend %___FLOW-icon;
	content: '\EA36';
}
.icon-small-arrow-right:before {
	@extend %___FLOW-icon;
	content: '\EA37';
}
.icon-small-arrow-up:before {
	@extend %___FLOW-icon;
	content: '\EA38';
}
.icon-social-facebook:before {
	@extend %___FLOW-icon;
	content: '\EA39';
}
.icon-social-linkedin:before {
	@extend %___FLOW-icon;
	content: '\EA3A';
}
.icon-social-twitter:before {
	@extend %___FLOW-icon;
	content: '\EA3B';
}
.icon-thumb-down-filled:before {
	@extend %___FLOW-icon;
	content: '\EA3C';
}
.icon-thumb-down:before {
	@extend %___FLOW-icon;
	content: '\EA3D';
}
.icon-thumb-up-filled:before {
	@extend %___FLOW-icon;
	content: '\EA3E';
}
.icon-thumb-up:before {
	@extend %___FLOW-icon;
	content: '\EA3F';
}
.icon-upload:before {
	@extend %___FLOW-icon;
	content: '\EA40';
}
.icon-user:before {
	@extend %___FLOW-icon;
	content: '\EA41';
}
.icon-volmachten-mandaten:before {
	@extend %___FLOW-icon;
	content: '\EA42';
}

// AFTER
.icon-arrow-right-after:after {
	@extend %___FLOW-icon;
	content: '\EA05';
}
