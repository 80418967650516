%__inline-list {
	@extend %__clearfix;
	padding-left: 0;
	list-style: none;

	> * {
		float: left;
	}
}

.c-inline-list {
	@extend %__inline-list;
}
