// =============================================================================
// :: Z-index
// =============================================================================
$z-index-space: 9999;
$z-index-sky: 1000;
$z-index-roof: 100;
$z-index-floor: 10;
$z-index-ground: 1;
$z-index-cellar: 0;
$z-index-pit: -1;
