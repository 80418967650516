// -------------------------------------------------------------------
// :: SPACING
// -------------------------------------------------------------------
// Helper classes for adding or removing space between elements

// -------------------------------------------------------------------
// :: PADDING
// -------------------------------------------------------------------
//TODO: Iben: Update these so they use the wrapper padding styles
.u-no-padding {
	padding: 0 !important;
}

.u-padding-sm {
	padding: $layout-spacing-sm !important;
}

.u-padding {
	padding: $layout-spacing-lg !important;
}

.u-padding-lg {
	padding: $layout-spacing-xl !important;
}

.u-padding-xl {
	padding: rem(40px) !important;
}

.u-layout-spacing-xl {
	padding: $layout-spacing-xl;
}

// -------------------------------------------------------------------
// :: PADDING BOTTOM
// -------------------------------------------------------------------

.u-padding-bottom {
	padding-bottom: $layout-spacing-lg !important;
}

// -------------------------------------------------------------------
// :: MARGIN
// -------------------------------------------------------------------
.u-no-margin {
	margin: 0 !important;
}

.u-margin {
	margin: $layout-spacing-lg !important;
}

// -------------------------------------------------------------------
// :: MARGIN TOP
// -------------------------------------------------------------------

.u-margin-top-sm {
	margin-top: $layout-spacing-sm !important;
}

.u-margin-top {
	margin-top: $layout-spacing-lg !important;
}

.u-margin-top-lg {
	margin-top: $layout-spacing-xl !important;
}

.u-margin-top-xl {
	margin-top: rem(40px) !important;
}

.u-margin-top-xxl {
	margin-top: rem(80px) !important;
}

// -------------------------------------------------------------------
// :: MARGIN RIGHT
// -------------------------------------------------------------------

.u-margin-right {
	margin-right: $layout-spacing-lg !important;
}

// -------------------------------------------------------------------
// :: MARGIN BOTTOM
// -------------------------------------------------------------------
.u-no-margin-bottom {
	margin-bottom: 0 !important;
}

.u-margin-bottom-sm {
	margin-bottom: $layout-spacing-sm !important;
}

.u-margin-bottom {
	margin-bottom: $layout-spacing-lg !important;
}

.u-margin-bottom-lg {
	margin-bottom: $layout-spacing-xl !important;
}

.u-margin-bottom-xxl {
	margin-bottom: rem(80px) !important;
}

// -------------------------------------------------------------------
// :: MARGIN LEFT
// -------------------------------------------------------------------

.u-margin-left-sm {
	margin-left: $layout-spacing-sm !important;
}

.u-margin-left {
	margin-left: $layout-spacing-lg !important;
}
