// Required Core Stylesheet
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';

// Optional Theme Stylesheet
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.theme';

.c-carousel {
	@include zigzag;
	padding: 126px 36px 72px;
	text-align: center;
}

.c-carousel__title {
	margin: 0 !important;
	padding-top: rem(64px);
	padding-bottom: rem(36px);
	padding-right: rem(36px);
	padding-left: rem(36px);

	background-color: $white;
}

// Carousel
.carousel {
	ul {
		padding-left: 0;
	}

	li {
		list-style: none;
		padding-left: 0;
		text-align: left;
		display: inline-block;
	}
}

.carousel.glide--swipeable {
	cursor: default !important;
}

.carousel {
	.carousel-inner {
		cursor: grab;
	}

	.carousel-indicators {
		li {
			cursor: pointer;
		}
	}
}

.carousel-indicators {
	position: relative;
	margin: 0;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	background-color: $white;
	display: block;
	padding-top: 18px;
	padding-bottom: 18px;

	@include respond-to($desktop) {
		padding-top: 18px;
		padding-bottom: 18px;
	}

	@include respond-at($desktop) {
		display: flex;
	}
}

.carousel-indicators > li {
	width: calc(100% - 72px);
	height: auto;
	background-color: transparent;
	text-indent: 0;
	position: relative;
	opacity: 1;
	margin-left: 36px;
	margin-right: 36px;
	margin-top: 18px;
	margin-bottom: 18px;
}

.carousel-indicators > li {
	@include respond-at($desktop) {
		width: 25%;
	}
}

.carousel-indicators > li:before,
.carousel-indicators > li:after {
	content: '';
	height: 4px;
	bottom: 0;
	left: 27px;
	display: block;
	position: absolute;
	border-radius: 4px;
}

.carousel-indicators > li:before {
	width: calc(100% - 27px);
	background-color: $white-92;
}

.carousel-indicators > li:after {
	width: 0;
	background-color: $primary-color;
}

.carousel-indicators > li.glide__bullet--active:after {
	-webkit-animation-name: width-100;
	animation-name: width-100;
	-webkit-animation-duration: 4.8s;
	animation-duration: 4.8s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
}

.carousel-indicators > li.glide__bullet--active .carousel-title {
	color: $primary-color;
}

.carousel-indicators > li p {
	margin-left: 27px;
	margin-bottom: 27px;
}

@keyframes width-100 {
	from {
		width: 0%;
	}

	to {
		width: calc(100% - 27px);
	}
}

.carousel-title {
	margin-bottom: 9px;
}

.carousel-control-prev,
.carousel-control-next {
	display: none;
}

.tab-count {
	position: absolute;
	top: 20px;
	left: 0;
	font-size: $font-size-xxxlarge;
	font-family: $font-family-secondary;
	line-height: 30px;
	color: $white-92;
}

.glide__bullet--active .tab-count {
	color: $primary-color;
}

.carousel-item {
	background-color: $white-98;
	text-align: center;
}

.carousel-img {
	position: relative;
	width: 100%;
	height: auto;

	@include respond-at($desktop) {
		max-width: 800px;
	}
}

.carousel-nr {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: block;
	font-size: $font-size-large;
	color: $primary-color;
	opacity: 0.5;
	font-family: $font-family-primary-bold;
}
