// -------------------------------------------------------------------
// :: COLORS
// -------------------------------------------------------------------
@import 'utils/_functions.scss';
@import '_quarks/_colors-eloket';

:root {
	--primary-color: #{$green-30};
	--primary-color-hover: #{$green-dark};
	--primary-color-dark: #{$green-dark};
	--primary-color-light: #{$green-light};
	--secondary-color: #{$blue-56};
	--indicator-background-color: #{$green-indicator-background};
	--selection-color: #{$selection-color-green};
}

// -------------------------------------------------------------------
// :: COMMON
// -------------------------------------------------------------------

@import '_globals-common';
