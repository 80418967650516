// INDICATOR Badge styles

//indicator inside a-tags
a .indicator {
	&:before {
		content: '';
		height: 20px;
		width: 20px;
		border-radius: 50%;
		background-color: $ui-red;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		z-index: -1;
	}

	position: relative;
	display: inline-block;
	width: 20px;
	text-align: center;
	color: $white;
	font-weight: 500;
	font-size: $font-size-xsmall;
	margin-left: 0.5rem;
	z-index: 1;
	height: 20px;
	line-height: $layout-spacing-lg;

	span[class*='icon-'] {
		&:before {
			display: inline-block;
			transform: translateY(-1px) translateX(0);
		}
	}

	&.indicator--orange:before {
		background-color: $ui-orange;
	}
}

.indicator--dashboard {
	position: relative;
	z-index: $z-index-ground;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: rem(30px);
	height: rem(30px);

	font-size: $font-size-medium;
	line-height: $layout-spacing-lg;
	font-weight: bold;

	margin-left: rem(16px);

	color: $primary-color;

	&::before {
		width: rem(30px) !important;
		height: rem(30px) !important;
		background-color: $indicator-background-color;
		content: '';
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		z-index: -1;
	}
}
