ul.c-list-checks {
	padding-left: 0;
	font-family: $font-family-primary;
	color: $text-color-black;
	font-size: rem(18px);
	display: block;
	margin: 0 auto;
	text-overflow: ellipsis;
	overflow: hidden;

	&:last-of-type {
		margin-bottom: $layout-spacing-lg;
	}

	@include respond-at($tablet) {
		margin: $layout-spacing-lg auto;
	}
}

@include respond-to($tablet) {
	.c-list-checks + .c-list-checks {
		margin-top: 0;
	}
}

.c-list-checks li {
	position: relative;
	margin-top: $layout-spacing-sm;
	padding-left: rem(44px);

	list-style-type: none;
	// padding-left: 0;

	&::before {
		content: '';
		position: absolute;
		display: block;
		width: 20px;
		height: 20px;
		top: 3px;
		left: 0;
		background: url('../../img/checkmark-green.svg') no-repeat center center transparent;
		background-size: contain;
	}
}

.c-list-checks li.cross {
	opacity: 0.6;

	&:before {
		background: url('../../img/checkmark-green.svg') no-repeat center center transparent;
		background-size: contain;
	}
}
