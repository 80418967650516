// -------------------------------------------------------------------
// :: Valuetile
// -------------------------------------------------------------------

.c-valuetile {
	display: inline-block;
	padding: 0.5rem 0;

	h4 {
		color: $primary-color;
		font-weight: bold;
		font-size: $font-size;
		position: relative;
		padding-right: $layout-spacing-lg;
		display: inline-block;
	}

	span {
		display: block;
	}

	li {
		padding: $layout-spacing-xs;
		padding-left: 0px;

		font-size: $font-size;
	}

	.c-info-tooltip {
		position: absolute;
		top: 0;
		right: 0;
	}
}

.c-valuetile--tablet-and-up {
	display: none;

	@include respond-at($tablet) {
		display: inline-block;
	}
}
