// -----------------------------------------------------------------------------
// :: Settings
// -----------------------------------------------------------------------------
$modal-button-color: rgba($white, 0.33);

// -----------------------------------------------------------------------------
// :: CDK
// -----------------------------------------------------------------------------
// Wouter: I have absolutely no idea as to why the top of the HTML tag would get
// shifted upwards, but it is what it is, I guess.
// REF: https://github.com/angular/components/issues/7390#issuecomment-486947151
.cdk-global-scrollblock {
	position: static !important;
	width: initial !important;
	overflow: hidden;
}

// -----------------------------------------------------------------------------
// :: Modal
// -----------------------------------------------------------------------------
.c-modal {
	position: fixed;
	top: 43px;
	left: 0;
	z-index: 9999;

	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: calc(100vh - $header-menu-height);
	overflow: auto;

	background: $white;

	transition: all $animation-fast;

	@include respond-at($mobile) {
		position: relative;
		top: 50%;
		left: unset;

		display: block;
		width: 100%;
		max-width: 500px;
		min-height: unset;
		margin-right: auto;
		margin-left: auto;

		transform: translateY(-50%);
	}
}

.c-modal-container {
	display: block;
	height: fit-content;
	width: 60vw;
	background-color: $white;
	border-radius: $border-radius;
	box-shadow:
		0 0 24px 0px rgb(0 0 0 / 9%),
		0 0 14px rgb(0 0 0 / 14%);
}

// -----------------------------------------------------------------------------
// :: Elements
// -----------------------------------------------------------------------------
.c-modal__header {
	display: inline-flex;
	gap: $layout-spacing-lg;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: $layout-spacing-lg;

	border-bottom: 1px solid $grey-light;
}

.c-modal__title {
	display: inline-block;
}

.c-modal__close {
	@extend .u-outline;
	border: none;
	background: none;
	font-size: $font-size;
}

.c-modal__body {
	flex: 1;
	height: 100%;
	overflow: auto;
	margin: $layout-spacing-xl $layout-spacing-lg $layout-spacing-lg;

	@include respond-at($mobile) {
		max-height: 400px;
	}

	.c-input:last-child {
		margin-bottom: 0;

		small {
			padding-bottom: 0;
		}
	}
}

.c-modal__footer {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: $layout-spacing-lg;
	gap: $layout-spacing-sm;

	text-align: center;

	border-top: 1px solid $grey-light;
}

// -----------------------------------------------------------------------------
// :: Modifiers
// -----------------------------------------------------------------------------
.c-modal--with-icon {
	[class*='icon-'] {
		font-size: 3em;
		color: $primary-color;
	}

	.c-modal__body {
		text-align: center;
	}
}

.c-modal--wide {
	max-width: 50rem;
}

.c-modal--small {
	max-width: 380px;
	padding: 60px 30px;
	position: relative;

	.c-modal__close {
		position: absolute;
		right: 20px;
		top: 20px;
	}

	.c-modal__header {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding: 0 0 $layout-spacing-sm;

		border-bottom: none;
	}

	.c-modal__body {
		margin: $layout-spacing-sm 0 0;

		text-align: center;
	}
}
