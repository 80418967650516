@import '../base/_quarks/_colors-eloket';

// -------------------------------------------------------------------
// :: MEDIA QUERIES
// -------------------------------------------------------------------
// Major breakpoints should be set in base/_typography.scss
// Minor breakpoint should be added where needed (inline)
$mobile-small: 360px;
$mobile: 480px;
$tablet: 680px;
$tablet-wide: 800px;
$desktop: 1056px;
$desktop-wide: 1140px;
$desktop-wide-extended: 1312px;
$desktop-ultra-wide: 1600px;
$desktop-2k: 2048px;

// -------------------------------------------------------------------
// :: COLORS
// -------------------------------------------------------------------

// import in project specific _globals style

// -------------------------------------------------------------------
// :: FONT-SIZES
// -------------------------------------------------------------------
// Base-font-sizes should be set in base/_typography.scss
// - http://www.awayback.com/revised-font-stack
// - http://practicaltypography.com/system-fonts.html

$font-family-sans: 'Flanders Art Sans', sans-serif;
$font-family-serif: 'Flanders Art Serif Pro', serif;
$font-family-mono: 'Droid Sans Mono', monospace;
$font-family-medium: 'Flanders Art Sans Medium', 'Arial', sans-serif;
$font-family-bold: 'Flanders Art Sans Bold', 'Arial', sans-serif;

$font-size-help: 45px; // h1 !!!!
$font-size-xxxlarge: 36px; // h1 !!!!
$font-size-xxlarge: 32px; // h2 !!!!
$font-size-xlarge: 26px; // h3
$font-size-large: 20px; // h4
$font-size-medium: 18px; // default
$font-size: 16px; // h5
$font-size-small: 14px; // h6, small, cite
$font-size-xsmall: 12px; // th

// Default border-radius value for input elements,
// alerts, buttons, images (in <figure>), ...

$line-height: 1.5em; // one size fits all

$border-radius: 0.3334em; // 5px @ 16px
$border-simple: 1px solid $border-color-grey;
$border-double: 2px solid $border-color-grey;

$box-shadow: 0 1px 3px 0 rgba($black, 0.1);
$box-shadow-card: 0 1px 12px rgba(104, 116, 131, 0.2);
$box-shadow-dashboard: 0 2px 5px 0 rgba($black, 0.05);
$box-shadow-big: 0 4px 10px 0 rgba($black, 0.2);
$text-shadow: 2px 2px 3px rgba($black, 0.1);

// -------------------------------------------------------------------
// :: LAYOUT
// -------------------------------------------------------------------
$max-width: 1240px;

$layout-spacing-xs: 0.25rem; // 4px
$layout-spacing-sm: 0.75rem; // 12px
$layout-spacing-lg: 1.25rem; // 20px
$layout-spacing-xl: 1.75rem; // 28px

// -------------------------------------------------------------------
// :: GRID
// -------------------------------------------------------------------
//$grid-gutter-row: 1.25rem;

// -------------------------------------------------------------------
// :: Z-INDEXES
// -------------------------------------------------------------------
$z-index-nav: 100;
$z-index-tooltips: 200;
$z-index-overlay: 300;
$z-index-leaflet-1: 400;
$z-index-leaflet-2: 500;
$z-index-header: 600;
$z-index-menu: 550;
$z-index-flyout: 700;
$z-index-modals: 800;

// -------------------------------------------------------------------
// :: ANIMATIONS
// -------------------------------------------------------------------
$animation-fast: 165ms ease-in-out;
$animation-medium: 330ms ease-in-out;
$animation-slow: 1000ms ease-in-out;

// -------------------------------------------------------------------
// :: HEADER
// -------------------------------------------------------------------
$header-height: 43px;
$header-menu-height: 42px;

// -------------------------------------------------------------------
// :: LAYOUT
// -------------------------------------------------------------------
