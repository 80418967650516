.c-tag {
	&--button {
		display: inline-flex;
		align-items: center;
		padding: $layout-spacing-sm;

		background-color: $tag-background;
		border: rem(1px) solid $tag-border;
		border-radius: $tag-border-radius;
	}

	&--link {
		display: inline-flex;
		align-items: center;
		background-color: transparent;
		border: 0;
	}
}

.c-tag__label {
	&--button {
		font-family: $font-family-primary;
		font-size: rem($tag-font-size);
		line-height: rem($tag-font-size * 2);
	}

	&--link {
		color: $blue-vlaanderen;
		text-decoration: underline;
	}
}

.c-tag__break {
	flex-basis: 100%;
	height: 0;
	margin: 0 0 $layout-spacing-sm $layout-spacing-xs;
}

.c-tag__trigger {
	display: flex;
	justify-content: center;
	align-items: center;
	width: rem($tag-trigger-size);
	height: rem($tag-trigger-size);
	margin-left: $layout-spacing-sm;

	background-color: $tag-icon-background;
	border: none;
	border-radius: $tag-trigger-border-radius;
	appearance: none;

	cursor: pointer;

	&:hover {
		.c-tag__trigger-icon {
			color: $tag-icon-hover;
		}
	}
}

.c-tag__trigger-icon {
	font-size: $font-size;
	line-height: rem(14px);
	transition: $tag-transition;
}
