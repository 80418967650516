.c-search {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-top: 0.8rem;
	margin-bottom: 2rem;
	align-items: center;

	@include respond-at($desktop) {
		flex-direction: row;
	}
}

.c-search__label {
	font-weight: 600;
}

.c-search__input {
	margin: 0;
	width: 100%;

	span {
		padding: 0 0.3rem;
	}

	input {
		box-shadow: none !important;
		padding-left: 4rem !important;
		border: rem(1px) solid $border-color-grey !important;
	}
}

.c-search__cta {
	@include respond-to($desktop) {
		width: 100%;
		margin-top: $layout-spacing-lg;
	}
}
