// -------------------------------------------------------------------
// :: TYPOGRAPHY
// -------------------------------------------------------------------
// - https://github.com/railsware/applepie
// - http://www.google.com/design/spec/style/typography.html
// - http://typecast.com/blog/a-more-modern-scale-for-web-typography
// - http://meyerweb.com/eric/thoughts/2006/02/08/unitless-line-heights
// - http://modularscale.com

@import url('https://fonts.googleapis.com/css?family=Droid+Sans+Mono&display=swap');

@include get-font('Flanders Art Sans Light', '../../fonts/FlandersArtSans-Light', 300);
@include get-font('Flanders Art Sans', '../../fonts/FlandersArtSans-Regular');
@include get-font('Flanders Art Sans Medium', '../../fonts/FlandersArtSans-Medium', 500);
@include get-font('Flanders Art Sans Bold', '../../fonts/FlandersArtSans-Bold', 700);

@include get-font('Flanders Art Serif Pro', '../../fonts/FlandersArtSerifPro-Regular');
@include get-font('Flanders Art Serif Pro Bold', '../../fonts/FlandersArtSerifPro-Bold', 500);

// =============================================================================
// :: Font families
// =============================================================================
$font-family-primary: ('Flanders Art Sans', 'Arial', sans-serif);
$font-family-primary-medium: ('Flanders Art Sans Medium', 'Arial', sans-serif);
$font-family-primary-bold: ('Flanders Art Sans Bold', 'Arial', sans-serif);

$font-family-secondary: ('Flanders Art Serif Pro', 'Times New Roman', serif);
$font-family-secondary-bold: ('Flanders Art Serif Pro Bold', 'Times New Roman', serif);

// =============================================================================
// :: Font weights
// =============================================================================
$font-weight-extrablack: 900;
$font-weight-black: 800;
$font-weight-ultrabold: 700;
$font-weight-bold: 600;
$font-weight-dark: 500;
$font-weight-normal: 400;
$font-weight-lite: 300;
$font-weight-thin: 200;
$font-weight-ultrathin: 100;

// -------------------------------------------------------------------
// :: BASE
// -------------------------------------------------------------------

html {
	font-size: rem(16px);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	color: $text-color-black;
	font-family: $font-family-sans;
	font-size: rem($font-size);
	line-height: $line-height;
	//font-weight: 300;
}

// -------------------------------------------------------------------
// :: NORMALIZE
// -------------------------------------------------------------------
// If a margin/padding is set somewhere, remove it from below
// Keep it DRY and prevent overwrites

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $text-color-black-title;
}

h1,
h2,
h4,
blockquote {
	font-family: $font-family-serif;
}

h1,
h2,
h3 {
	small {
		font-weight: 400;
		margin-left: 0.8rem;
	}
}

h4,
h5 {
	font-family: $font-family-sans;
	line-height: $line-height;
	font-weight: bold;
}

small {
	display: inline-block;
}

// For the H1: the overline-ornament is only showed when
// the class .h1 is added. To prevent crossover issues.
h1,
.h1 {
	font-size: rem($font-size-xlarge);
	line-height: 1.1875;
	font-weight: 500;
	margin-bottom: 2rem;

	@include respond-at($tablet) {
		font-size: rem($font-size-xxlarge);
	}
}

.h1 {
	padding-top: $layout-spacing-sm;
	border-top: 5px solid $grey-light;

	// display: table to make the 100% of the :before equal to the
	// width of the text without loosing display block characteristics

	display: table;
}

h2 {
	font-size: rem($font-size-xlarge);
	font-weight: normal;
	line-height: 1.194;
}

h3 {
	font-size: rem($font-size-large);
	line-height: 1.23;
	margin-bottom: 1rem;

	@include respond-at($tablet) {
		font-size: rem($font-size-xlarge);
	}
}

h4,
.h4 {
	font-size: rem($font-size-medium);
	line-height: 1.5;

	@include respond-at($tablet) {
		font-size: rem($font-size-large);
	}

	+ p {
		margin-top: 0.5rem;
	}
}

h5 {
	font-size: rem($font-size);
	line-height: 1.25;
	margin-bottom: 0.5rem;

	+ p {
		margin-top: 0;
	}
}

h6 {
	font-size: 1.1rem;
	margin-top: 0.9rem;
}

p {
	font-size: rem($font-size);
	margin: 1.2em 0;
	color: $text-color-black;
	line-height: rem(24px);

	&:first-child {
		margin: 0;
	}

	[class*='icon'] {
		line-height: 1;

		&:before {
			font-size: 2.5em;
		}
	}
}

p + {
	h1,
	h2,
	h3 {
		margin-top: 3rem;
	}

	h4,
	h5 {
		margin-top: 2rem;
	}
}

// -------------------------------------------------------------------
// :: MARGINS
// -------------------------------------------------------------------
// If a margin/padding is set somewhere, remove it from below
// Keep it DRY and prevent overwrites

h1,
h2,
h3,
h4,
p,
h5,
h6,
ul,
ol,
body {
	margin: 0;
}

// -------------------------------------------------------------------
// :: BLOCK ELEMENTS
// -------------------------------------------------------------------

small,
cite {
	font-size: rem($font-size-small);
}

.primary-color {
	color: $primary-color !important;
}

.white-color {
	color: $text-color-light !important;
}

.ui-color-orange {
	color: $ui-orange;
}

.ui-color-red {
	color: $ui-red;
}

.ui-color-blue {
	color: $ui-blue;
}

.ui-color-black {
	color: $font-color-medium;
}

// -------------------------------------------------------------------
// :: DECORATORS
// -------------------------------------------------------------------

// Hyperlinks: the hover effect with the :after element is only active
// when the class .is-hyperlink is added to the element or when the
// a-tag is inside a paragraph - This to prevent unwanted after
// elements when it is not needed (example grid links)

a {
	text-decoration: none;
}

.u-link--bold {
	font-weight: 700;
}

.u-link__underline {
	transition: text-decoration $animation-fast;

	&:hover,
	&:active {
		// Wouter: Override the default underline of 'none'
		text-decoration: underline !important;
	}
}

.u-translation-link {
	@extend .u-link__underline;
	@extend .u-outline;
	@extend .font-bold;
	@extend .primary-color;

	&.external-link {
		&::after {
			display: inline;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			font-family: 'Font Awesome 6 Sharp';
			font-weight: 900;
			content: '\f08e';
			padding-left: $layout-spacing-xs;
			font-size: rem(13px);
		}
	}
}

// Highlight text-selection
// And adjust font-color for
// deleted/updated content

//mark {
//  background: $ui-yellow;
//}

del {
	color: $font-color-medium;
}

// A better looking horizontal ruler
// Inspired by the HTML5 Boilerplate
// http://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css

hr {
	border: none;
	border-top: 1px solid $border-color;
	box-sizing: content-box;
	margin: 1.75em 0;
	//display: block;
	//padding: 0;
	//height: 0;
}

hr.extra-top-margin {
	margin-top: 4em;
}

// Blockquote and
// author name (cite)

blockquote {
	font-weight: 100;
	font-size: rem(26px);
	line-height: 1.25;
	margin: 0;
}

cite {
	color: $font-color-medium;
	font-family: $font-family-sans;
	font-style: normal;
	margin-top: 0.75em;
	display: block;
	border-radius: $border-radius;
}

cite:before {
	content: '\2014';
	margin: 0 0.3em;
}

code {
	text-transform: none;
	background: $grey-light;
	padding: 0.2rem;
	border: $border-simple;
	border-radius: $border-radius;
}

// Label
label[for] {
	cursor: pointer;
}

small[class*='icon-'] {
	position: relative;

	&:before {
		position: absolute;
		left: -2rem;
		font-size: $font-size-large;
		color: $primary-color-dark;
		top: 50%;
		transform: translateY(-50%);
	}
}

// -------------------------------------------------------------------
// :: HOME
// -------------------------------------------------------------------
.home {
	h1 {
		font-family: $font-family-secondary-bold;
		font-size: $font-size-xxlarge;
		line-height: 1.1875;
		margin: 0;
		margin-bottom: $layout-spacing-sm;
	}

	h2 {
		font-family: $font-family-secondary;
		font-size: $font-size-xxxlarge;
		margin-bottom: $layout-spacing-sm;
	}

	h3 {
		font-family: $font-family-secondary;
		line-height: 1.1875;
		font-size: $font-size-xlarge;
		font-weight: 400;
		margin: 0;
	}

	h4 {
		font-family: $font-family-primary-bold;
		font-weight: 400;
		font-size: $font-size-large;
		line-height: rem(27px);
		margin-bottom: $layout-spacing-lg;
	}

	p {
		font-family: $font-family-primary !important;
		font-size: $font-size;
		line-height: rem(24px);
		margin-bottom: $layout-spacing-lg;
		margin-top: $layout-spacing-sm;
		color: $text-color-black;
	}

	small {
		font-family: $font-family-primary;
		font-size: $font-size-small;
		line-height: rem(16px);
		color: $text-color-base;
		text-transform: uppercase;
	}
}

// -------------------------------------------------------------------
// :: FONT WEIGHTS
// -------------------------------------------------------------------

.font-bold {
	font-family: $font-bold-font-family;
	font-weight: $font-bold-font-weight !important;
}
