// -------------------------------------------------------------------
// :: VARIABLES
// -------------------------------------------------------------------

$container-max-width: 1280px;
$container-margin-xl: auto;
$container-margin-l: auto;
$container-margin: auto;

// -------------------------------------------------------------------
// :: GLOBAL LAYOUT
// -------------------------------------------------------------------

html {
	min-height: 100vh;
	overflow-x: hidden;
}

body {
	min-height: 100vh;
	display: flex;
	@include respond-at($tablet) {
		padding-bottom: 0;
	}
}

.u-overflow-y--hidden--vp9 {
	@include respond-to($desktop) {
		overflow-y: hidden;
	}
}

.u-overflow-y--hidden--vp12 {
	@include respond-to($desktop-wide-extended) {
		overflow-y: hidden;
	}
}

.u-overflow-y--hidden {
	overflow-y: hidden;
}

.u-display-none {
	display: none;
}

button,
a {
	cursor: pointer;
}

.menu-offset {
	margin: 0;
	padding-top: $header-menu-height;
}

.u-wrapper {
	@extend %__wrapper;
	position: relative;
}

%__wrapper {
	width: 100%;
	max-width: $max-width;
	margin-left: auto;
	margin-right: auto;
	padding-left: $layout-spacing-lg * 0.5;
	padding-right: $layout-spacing-lg * 0.5;

	@include respond-at($tablet) {
		padding-left: $layout-spacing-lg;
		padding-right: $layout-spacing-lg;
	}
}

::selection {
	background: $selection-color;
}

::-moz-selection {
	background: $selection-color;
}

[placeholder] {
	text-overflow: ellipsis;
}

// -------------------------------------------------------------------
// :: UTILITY HELPERS
// -------------------------------------------------------------------
// To provide screen readers an optimal experience
// add hidden DOM-elements with additional info
//
// - http://css-tricks.com/places-its-tempting-to-use-display-none-but-dont

.u-background-white {
	background-color: $white;
}

.u-background-lighter {
	background-color: $grey-lighter;
}

.u-background-light {
	background-color: $grey-light;
}

.u-border-top-lighter {
	border-top: 1px solid $grey-lighter;
}

.u-border-bottom-lighter {
	border-bottom: 1px solid $grey-lighter;
}

.u-border-top-light {
	border-top: 1px solid $grey-light;
}

.u-border-bottom-light {
	border-bottom: 1px solid $grey-light;
}

// To provide screen readers an optimal experience
// add hidden DOM-elements with additional info
.u-is-invisible {
	width: 1px !important;
	height: 1px !important;
	margin: -1px;
	padding: 0;
	border: none;
	clip: rect(0 0 0 0);
	position: absolute;
	overflow: hidden;
}

.u-scrollable {
	overflow-y: scroll;
}

.u-right {
	float: right;
}

.u-left {
	float: left;
}

%__u-truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.u-truncate {
	@extend %__u-truncate;
}

// Utility classes for
// responsive behaviour

.u-block {
	display: block;
}

.u-inline {
	display: inline-block;
}

.u-is-hidden {
	display: none;
}

.u-is-hidden-on-mobile {
	display: none;

	@include respond-at($tablet) {
		display: inline-block;
	}
}

.u-is-hidden-on-tablet-and-up {
	@include respond-at($tablet) {
		display: none;
	}
}

.u-is-hidden-on-desktop {
	@include respond-at($desktop) {
		display: none !important;
	}
}

.u-outline {
	&:focus-visible {
		outline: auto;
		outline-offset: 2px;
		outline-width: 2px;
		outline-color: $link-color;
		outline-style: solid;
	}
}

.u-outline-white {
	@extend .u-outline;

	&:focus-visible {
		outline-color: $white;
	}
}

.u-outline-none {
	&:focus-visible {
		outline: none;
	}
}

.u-outline-sm {
	// This outline with negative offset makes it so that the
	// outline is on the inside of the border.
	&:focus-visible {
		outline: auto;
		outline-offset: -2px;
		outline-width: 2px;
		outline-color: $link-color;
		outline-style: solid;
	}
}

.u-full-width {
	width: 100%;
}

.v-center-outer {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;

	.v-center {
		display: table;
		height: 100%;
		width: 100%;

		.v-center-inner {
			display: table-cell;
			vertical-align: middle;
			width: 100%;
			text-align: center;
		}
	}
}

.u-global-prevent-pointer-events {
	// Target the main content to omit the prevention of the topbar
	.c-eloket--main-content {
		pointer-events: none;
		cursor: default;
	}
}

.u-stripe-underline {
	padding-bottom: $layout-spacing-lg;
	width: fit-content;

	background: url('/assets/img/arcering.png') repeat-x bottom;
}

// -------------------------------------------------------------------
// :: MIXINS
// -------------------------------------------------------------------
// Custom mixins to make standard behaviour & css more modular
//
//

// :: CLEARFLOAT
// clear float that can be applied to an element where all the childeren are floated.

%__clearfix {
	&:before,
	&:after {
		content: '';
		display: table;
	}

	&:after {
		clear: both;
	}
}

.clearfix {
	@extend %__clearfix;
}

// -----------------------------------------------------------------------------
// :: Utils
// -----------------------------------------------------------------------------

.u-pull-up {
	margin-top: -144px;

	@media screen and (max-width: 768px) {
		margin-bottom: 0;
	}
}

.c-container {
	margin-left: $container-margin;
	margin-right: $container-margin;
	max-width: rem($container-max-width);

	@include respond-at($desktop) {
		margin-left: $container-margin-l;
		margin-right: $container-margin-l;
	}

	@include respond-at($desktop-ultra-wide) {
		margin-left: $container-margin-xl;
		margin-right: $container-margin-xl;
	}

	@include respond-at($desktop-2k) {
		margin-left: $container-margin;
		margin-right: $container-margin;
	}
}

.c-container__menu {
	margin-left: auto;
	margin-right: auto;
}

.c-container--spacing {
	width: calc(100% - (36px * 2));
}

.c-container-sm {
	max-width: 900px;
}

.u-sr-only {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	width: 1px;
	height: 1px;
	overflow: hidden;
	margin: -1px;
	padding: 0;
	white-space: nowrap;
	border: none;
}

.u-reset-list {
	margin: 0;
	padding: 0;

	list-style-type: none;
}

.u-list__item {
	& + .u-list__item {
		margin-top: rem(8px);
	}
}

.u-list__item-large {
	& + .u-list__item-large {
		margin-top: rem(32px);
	}
}

.u-flex {
	display: flex;
}

.u-wrap {
	flex-wrap: wrap;
}

.u-cols {
	flex-direction: column;
}

.u-flex--justify-center {
	justify-content: center;
}

.u-flex--align-center {
	align-items: center !important;
}

.u-flex--align-end {
	align-items: flex-end !important;
}

.u-flex--wrap {
	flex-wrap: wrap;
}

.u-flex--justify-end {
	justify-content: flex-end;
}

.u-overflow-hidden-y {
	overflow-y: hidden;
}

.u-inline-wrap {
	$space: 16px * 0.5;

	margin: $space * -1;

	& > * {
		margin: $space;
	}
}

.u-text-align-center {
	text-align: center;
}

.u-inline-list {
	&:not(:last-of-type) {
		&::after {
			content: ', ';
			display: inline-block;
		}
	}
}
