// -------------------------------------------------------------------
// :: TYPOGRAPHY
// -------------------------------------------------------------------

// Utility classes to set text-transform
.u-text-transform {
	&-none {
		text-transform: none;
	}
}

// Utility classes to set text-align
.u-text-align {
	&-center {
		text-align: center;
	}
}

//use monospaced font
.u-monospace {
	font-family: $font-family-mono;
}

.u-italic {
	font-style: italic;
}

.u-error {
	color: $ui-error;
}

.u-success {
	color: $primary-color;
}
