.preloader {
	position: absolute;
	top: 50%;
	width: 100%;
	transform: translateY(-50%);
	text-align: center;

	.icon-flanders {
		font-size: 80px;
	}

	span {
		line-height: 80px;
	}
}
