// -------------------------------------------------------------------
// :: Animations
// -------------------------------------------------------------------

// breathing in-out animation.
// Used in:
//   * preloader

@keyframes breathe {
	0% {
		opacity: 0.1;
	}
	32% {
		opacity: 1;
	}
	//95%	{ opacity: 0.1; }
	100% {
		opacity: 0.1;
	}
}

// Fade In
@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.u-remove-pointer-events {
	pointer-events: none;
}
