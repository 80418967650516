// TODO: check breakpoints for the filters (Denis)

.c-filter {
	&.is-open {
		.c-filter__container {
			@include respond-to($desktop-wide) {
				transform: translateX(0);

				pointer-events: all;
				visibility: visible;
			}
		}

		.c-filter__filters {
			@include respond-to($desktop-wide) {
				// Wouter: different overflow on both axes (not unset) with one set to visible
				// makes the latter behave as 'auto', which displays a scroll
				overflow-y: scroll;
				overflow-x: hidden;
			}
		}
	}
}

.c-filter__container {
	@include respond-at($desktop-wide) {
		padding: $layout-spacing-lg;
		background-color: $filter-container-background-lg;

		border: rem(1px) solid $border-color-grey;
	}

	@include respond-to($desktop-wide) {
		position: fixed;
		top: 0;
		left: 0;
		z-index: $z-index-space + 2;

		width: 100%;
		height: 100%;
		padding: 0 $layout-spacing-lg;

		background-color: $filter-container-background-sm;

		transform: translateX(-100%);
		transition: transform 0.2s ease-in-out;

		pointer-events: none;
		visibility: hidden;
	}
}

.c-filter__close {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: $layout-spacing-lg;

	font-size: $font-size-xlarge;

	appearance: none;
	border: none;
	background-color: transparent;

	@include respond-at($desktop-wide) {
		display: none;
	}
}

.c-filter__filters {
	overflow: visible;

	.u-reset-list {
		.u-list__item {
			display: flex;
			line-height: unset;
			margin-top: $filter-margin-top;
		}
	}
}

.c-filter__filters-list {
	@include respond-to($desktop-wide) {
		padding-top: $layout-spacing-lg;
		padding-bottom: rem(110px);
	}
}

.c-filter__results-button {
	position: fixed;
	bottom: $layout-spacing-lg;

	width: calc(100% - #{rem(40px)});

	button {
		width: 100%;
	}

	@include respond-at($desktop-wide) {
		display: none;
	}
}

.c-filter__title {
	padding-bottom: $layout-spacing-xs;
	margin-bottom: $layout-spacing-sm;

	font-family: $filter-font-family;
	font-size: rem($filter-font-size);
	line-height: $layout-spacing-lg;

	border-bottom: rem(1px) solid $filter-title-border;
}

.c-filter__item {
	& + .c-filter__item {
		margin-top: $layout-spacing-sm;
	}
}

.c-filter__label-count {
	color: $filter-label-count;
}
