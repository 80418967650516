// -----------------------------------------------------------------------------
// :: Eloket
// -----------------------------------------------------------------------------

vlaio-app {
	.c-eloket {
		position: relative;
		background-color: $grey-light;

		@include respond-at($tablet) {
			height: 100%;
		}

		.c-search-select {
			display: block;
			margin: 1rem;
			margin-bottom: 0.3rem;
			width: calc(100% - 2rem);
			z-index: $z-index-nav;

			@include respond-at($tablet) {
				margin: 1rem auto;
				width: 30rem;
			}
		}

		&--main-content {
			display: grid;
			align-content: space-between;
			min-height: 100%;
			grid-template-rows: auto 0px 1fr min-content;
			grid-template-columns: 100%;

			router-outlet {
				grid-row: 2/3;
			}

			vlaio-app-base-route {
				grid-row: 3/4;
			}

			vlaio-footer {
				grid-row: 4/5;
			}

			// Wouter: Styles when the user is on the "mijn loket" part of the app.
			&:has(vlaio-e-loket) {
				vlaio-menu .sub-menu {
					display: none;
				}

				vlaio-app-base-route {
					padding-top: $header-height;
				}
			}

			// Wouter: Styles on the general part of the app.s
			&:not(:has(vlaio-e-loket)) {
				router-outlet {
					+ vlaio-app-base-route {
						padding-top: calc($header-height * 2);
					}
				}

				// TODO: Iben: See how much of this is still needed once we get rid of the older styling
				.c-container {
					h1,
					h4 {
						word-break: break-word;
					}

					article {
						div {
							margin-bottom: $layout-spacing-lg;
						}

						a {
							&:hover,
							&:active {
								outline: none;

								text-decoration: underline;

								color: $primary-color-dark;
							}

							@extend .u-outline;
							@extend .font-bold;
						}
					}
				}
			}
		}
	}
}

// -----------------------------------------------------------------------------
// :: Elements
// -----------------------------------------------------------------------------

.c-eloket__content__title {
	text-align: left;
	padding: 1rem 0;
	border-bottom: $border-simple;
	margin-bottom: 1rem;

	> h1 {
		margin: 0;
		padding: 0;

		@include respond-at($tablet) {
			margin-top: 0;
		}
	}

	@include respond-at($tablet) {
		padding: 0;
		border-bottom: none;
	}
}

.c-eloket__emptystate {
	text-align: center;
	max-width: 28rem;
	width: 100%;
	margin: 20vh auto;

	img {
		width: 100px;
		height: auto;
	}

	[class*='icon-'] {
		color: $grey-medium;
		display: block;
		font-size: 70px;
		margin-bottom: 1rem;
	}
}

.c-eloket__meta {
	display: block;
	flex-direction: row-reverse;
	text-align: right;
	font-size: $font-size-small;
	color: $grey;
	line-height: 1.6rem;
	margin-top: -1.6rem;

	&.c-eloket__wrapper {
		margin-top: 0;

		@include respond-at($tablet) {
			margin-top: -1rem;
		}

		@include respond-at($desktop) {
			margin-top: -1.6rem;
		}
	}
}

// -----------------------------------------------------------------------------
// :: Modifiers
// -----------------------------------------------------------------------------
.c-eloket__content--no-background {
	background-color: transparent;
}

/**
 * Status based text highlights
 */

.is-success {
	color: $primary-color;
}

.is-warning {
	color: $ui-orange;
}

.is-error {
	color: $ui-red;
}
