/*
 * Leaflet overrides
 */

.leaflet-top,
.leaflet-bottom {
	z-index: $z-index-leaflet-2 !important;
}

.leaflet-pane {
	z-index: $z-index-leaflet-1 !important;
}
