// -----------------------------------------------------------------------------
// :: Host
// -----------------------------------------------------------------------------
.c-eloket-nav__container {
	background-color: $white;
	min-height: 100%;
	@include respond-at($tablet) {
		min-width: 5em;
	}
}

// -----------------------------------------------------------------------------
// :: Wrapper
// -----------------------------------------------------------------------------
.c-eloket-nav__wrapper {
	position: relative;
	height: 100%;
	background-color: $white;

	@include respond-at($tablet) {
		&.hover {
			max-width: 5em;
			overflow-x: visible;
		}
	}
}

// -----------------------------------------------------------------------------
// :: Navigation
// -----------------------------------------------------------------------------
.c-eloket-nav {
	position: fixed;
	z-index: $z-index-header;
	bottom: 0;
	left: 0;
	right: 0;

	flex-shrink: 0;
	width: 100vw;
	background-color: $white;
	overflow-y: auto;

	// -------------------------------------------------
	// :: Media Queries
	// -------------------------------------------------

	@include respond-at($tablet) {
		width: fit-content;
		overflow: hidden;
		display: block;
		bottom: unset;
		position: relative;
		height: 100%;

		&::after {
			content: '';
			border-right: $border-simple;
			height: 100%;
			right: 0;
			position: absolute;
		}
	}

	// -------------------------------------------------
	// :: Children
	// -------------------------------------------------

	ul {
		@extend %__inline-list;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;

		@include respond-at($tablet) {
			flex-direction: column;
		}
	}

	li {
		min-height: 5rem;
		width: 25%;
		position: relative;
		float: none;
		display: flex;
		vertical-align: top;
		border-top: $border-simple;
		transition: background-color $animation-fast;
		box-sizing: border-box;
		padding: 2px;

		@include respond-at($tablet) {
			width: 100%;
			border-top: none;
			border-right: $border-simple;
		}

		@include respond-to($desktop) {
			&.c-eloket-nav__item--large {
				height: 6rem;

				span.label {
					top: 68%;
				}
			}
		}

		a,
		button {
			color: $text-color-black;
			height: 100%;
			min-height: 5rem;
			box-sizing: border-box;
			text-align: center;
			text-decoration: none;

			display: flex;
			flex-direction: column;
			align-content: center;
			align-items: center;
			padding: 0.25rem;
			width: 100%;

			&:focus-visible {
				outline: auto;
				outline-color: $link-color;
			}

			@include respond-to($tablet) {
				justify-content: center;
				align-self: center;
			}

			@include respond-at($tablet) {
				justify-content: center;

				span.label {
					font-size: rem($font-size-small);
				}
			}

			@include respond-at($desktop) {
				flex-direction: row;
				width: 100%;

				span.label {
					position: static;
					display: inline-block;
					top: auto;
					left: auto;
					width: auto;
					text-align: left;
					margin-left: 1rem;
					font-size: rem($font-size);
				}
			}

			span[class*='icon-'] {
				font-size: rem($font-size-xlarge);
				flex-grow: 0;
			}

			vlaio-icon {
				// Wouter: FA icons have a larger bounding box.
				font-size: rem($font-size-xlarge - 2px);
				// Wouter: Defining width and height prevents font indentation.
				width: rem($font-size-xlarge);
				height: rem($font-size-xlarge);
			}

			span.label {
				font-size: rem($font-size-small);
				line-height: 1.3;
			}

			span.no-label {
				@extend .label;

				@include respond-at($tablet) {
					display: none;
				}
			}

			span.indicator {
				position: absolute;
				right: rem(10px);
				top: rem(10px);

				display: flex;
				justify-content: center;
				align-items: center;
				width: rem(16px);
				height: rem(16px);

				font-size: rem(14px);
				line-height: rem(20px);
				font-weight: bold;

				margin-left: rem(16px);

				color: $primary-color;

				&::before {
					width: rem(24px);
					height: rem(24px);
					background-color: $indicator-background-color;
				}

				@include respond-to($tablet) {
					display: flex !important;
				}

				@include respond-at($tablet) {
					top: 50%;
					right: rem(8px);

					width: rem(30px);
					height: rem(30px);

					font-size: rem(17px);

					transform: translateY(-50%);

					&::before {
						width: rem(30px);
						height: rem(30px);
					}
				}
			}
		}

		span[class*='icon-'] {
			font-size: 2em;
			line-height: 1;
		}

		&:hover {
			background-color: $background-color-light;
		}

		&:active {
			background-color: rgba($grey-light, 0.7);
		}

		&.is-active {
			background-color: $background-color-light;

			@include respond-at($tablet) {
				font-weight: bolder;
			}

			.indicator {
				&::before {
					background-color: $background-color-base;
				}
			}
		}

		&.is-disabled {
			z-index: 10;
			border-top-color: rgba($grey-medium, 0.2);
			background-color: rgba($grey-medium, 0.2);

			a {
				color: rgba($grey, 0.4);

				cursor: not-allowed;
			}
		}
	}

	// -------------------------------------------------
	// :: Modifiers
	// -------------------------------------------------

	&__item {
		display: flex;
		justify-content: center;
		align-items: center;

		vlaio-icon {
			font-size: rem(22px);
		}
	}

	&__item--label {
		pointer-events: none;
		padding-bottom: 0.5rem;

		min-height: fit-content !important;
	}

	&__item--subnav {
		.m-eloket-nav__item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			min-height: auto;
		}

		.m-eloket-nav__subnav {
			height: 0;
			overflow: hidden;
		}

		&.is-open {
			.m-eloket-nav__arrow {
				transform: rotate(180deg);
			}

			.m-eloket-nav__text {
				font-weight: 500;
			}

			.m-eloket-nav__subnav {
				height: auto;
			}
		}
	}

	&-list {
		&__list {
			padding: 0;
			list-style: none;
		}

		&__item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			border-bottom: 1.8px solid $grey-light;

			&.c-eloket-nav-list__item-last {
				border-bottom: none;
			}
		}

		&__link {
			width: 100%;
			padding: 1rem 0;
			text-decoration: none;
			color: $black;
		}

		&__icon {
			padding-right: 1rem;
			font-size: 1.2rem;
			float: right;
		}
	}
}

// -----------------------------------------------------------------------------
// :: Subnavigation
// -----------------------------------------------------------------------------
.c-eloket-subnav {
	@include respond-at($tablet) {
		display: block;
	}

	&__link {
		font-weight: 400;
		font-size: inherit;

		@include respond-at($tablet) {
			font-size: $font-size-small;
		}

		@include respond-at($desktop) {
			padding-left: 3.7rem;
		}
	}
}

// -----------------------------------------------------------------------------
// :: Button
// -----------------------------------------------------------------------------
.c-eloket-nav__button {
	// -------------------------------------------------
	// :: Children
	// -------------------------------------------------

	position: absolute;
	right: -$layout-spacing-sm;
	top: $font-size-xlarge;
	z-index: $z-index-header + 1;

	border: $border-simple;
	border-radius: 100%;
	background-color: $white;
	width: rem(24px);
	height: rem(24px);

	display: grid;
	place-items: center;

	// -------------------------------------------------
	// :: Media Queries
	// -------------------------------------------------

	@include respond-to($tablet) {
		display: none;
	}

	&:focus-visible {
		outline-color: $link-color;
	}

	// -------------------------------------------------
	// :: Modifiers
	// -------------------------------------------------

	span[class*='icon-'] {
		position: absolute;
		top: rem(-2px);

		font-size: rem(19.2px);
		scale: 0.8;
	}

	&.closed {
		right: rem(-90px);

		span[class*='icon-'] {
			left: rem(2px);
		}
	}

	&.hover {
		// This value needs to be specific so that the button
		// gets centered on the right border on the overlay
		right: rem(-171px);
	}

	&:hover {
		cursor: pointer;
	}
}

.c-eloket-nav__arrow {
	position: absolute;
	right: 1rem;
	display: flex;
	transition: transform 0.3s ease-in-out;

	&:before {
		font-size: 1rem;
	}
}

// -----------------------------------------------------------------------------
// :: Mobile Only
// -----------------------------------------------------------------------------
.c-eloket-nav__show-more-menu {
	transform-origin: bottom right;
	background-color: $white;
	border: $border-simple;
	box-shadow: $box-shadow;
	display: flex;
	position: fixed;
	bottom: 5.5rem;
	right: 0.5rem;
	width: 80%;

	li {
		display: block;
		width: 100%;
		min-height: auto;

		&:first-child {
			border-top: none !important;
		}

		a {
			position: relative;
			padding: 1rem;
			display: flex;
			height: 3.5rem;
			justify-content: center;
			flex-direction: row;
			width: 100%;

			span[class^='icon-'] {
				font-size: rem($font-size-xlarge);
			}

			span.label {
				position: static;
				transform: none;
				text-align: left;
				display: inline-block;
				padding-left: 1rem;
				font-size: rem($font-size);
			}

			.indicator {
				top: -1.25rem;
			}
		}
	}
}

.mobile-only {
	@include respond-at($tablet) {
		display: none !important;
	}

	&.has-sub-menu {
		flex-direction: column !important;

		li a {
			justify-content: flex-start;
		}

		ul li a {
			padding-left: 3em;
		}
	}
}

// -----------------------------------------------------------------------------
// :: Desktop Only
// -----------------------------------------------------------------------------

.c-eloket-nav {
	&__open {
		@include respond-at($tablet) {
			width: 15rem;
		}

		@include respond-at($desktop) {
			ul li a {
				padding-left: 1em;
				align-content: flex-start;
				justify-content: flex-start;
			}
		}
	}

	&__closed {
		@include respond-at($tablet) {
			width: 5rem;

			li a {
				display: grid;
				place-items: center;
			}
		}
	}
}

.desktop-only {
	@include respond-to($tablet) {
		display: none !important;
	}
}
// -----------------------------------------------------------------------------
// :: Modifiers
// -----------------------------------------------------------------------------
.disabled-wrapper {
	cursor: not-allowed;
}

.disabled {
	pointer-events: none;
}

.has-sub-menu {
	flex-direction: column !important;
}

.m-eloket-nav {
	flex-shrink: 0;

	@include respond-at($tablet) {
		flex-basis: 6.5rem;
	}

	@include respond-at($desktop) {
		flex-basis: 18rem;
	}
}

.c-eloket-nav.c-eloket-nav--small {
	overflow: hidden;

	@include respond-at($desktop) {
		flex-basis: 3.6rem;

		.c-eloket-nav__arrow {
			right: 0;
		}

		&:hover {
			flex-basis: unset;
		}
	}
}

.c-eloket-nav__more-btn {
	border: none;
	background-color: transparent;
}
