.c-filters__form-row-item {
	flex: 1;

	& + .c-filters__form-row-item {
		margin-top: $layout-spacing-lg;

		@include respond-at($desktop) {
			margin-left: rem(40px);
			margin-top: 0;
		}
	}
}
