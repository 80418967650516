@import '../variables/colors.scss';
@import '../base/_quarks/_layout.scss';

// -------------------------------------------------------------------
// :: VARIABLES
// -------------------------------------------------------------------

$table-header-font-family: ('Flanders Art Sans', sans-serif);
$table-header-text-transform: uppercase;
$table-header-size: rem(14px);
$table-font-size: rem(14px);
$table-line-height: rem(16px);
// Wouter: This deviates from the layout-spacing, but it's a conscious decision to make the table more readable.
$table-row-padding: rem(16px);

// -------------------------------------------------------------------
// :: TABLE
// -------------------------------------------------------------------

.c-sort {
	$sort-triangle-width: 6px;
	$sort-triangle-height: 6px;
	$sort-triangle-spacing: 2px;
	$sort-triangle-default-color: $black;
	$sort-triangle-active-color: $primary-color;

	cursor: pointer;
	display: block;
	position: relative;

	height: $sort-triangle-height * 2 + $sort-triangle-spacing;
	width: $sort-triangle-width;

	&::before,
	&::after {
		content: '';
		width: 0;
		height: 0;
		position: absolute;
		border-style: solid;

		transition: border-color $animation-fast;
	}

	&::before {
		top: 0;

		border-width: 0 ($sort-triangle-width * 0.5) $sort-triangle-height;
		border-color: transparent transparent $sort-triangle-default-color;
	}

	&::after {
		bottom: 0;

		border-width: $sort-triangle-height ($sort-triangle-width * 0.5) 0;
		border-color: $sort-triangle-default-color transparent transparent;
	}

	&.is-ascending {
		&::before {
			border-color: transparent transparent $sort-triangle-active-color;
		}
	}

	&.is-descending {
		&::after {
			border-color: $sort-triangle-active-color transparent transparent;
		}
	}
}

.vlaio-table {
	table {
		border-spacing: 0;
		margin: 0;

		border-collapse: collapse;
		border: none !important;
		table-layout: initial !important;

		th {
			position: relative;

			font-family: $table-header-font-family !important;
			text-transform: $table-header-text-transform !important;
			font-size: $table-header-size !important;
		}
	}

	thead {
		.ngx-table-header-cell {
			font-weight: bold !important;
		}
	}

	tbody {
		border-bottom: 1px solid $border-color-grey;

		.ngx-table-row {
			border: 1px solid $border-color-grey;
			border-bottom: none;

			&:focus-visible {
				outline-color: $primary-color;
			}

			&.ngx-table-row-even {
				background: $table-even-color;
			}

			&.ngx-table-row-odd {
				background: $white;
			}

			&.ngx-table-row-selected {
				border-color: $primary-color;
				background: $white !important;

				&.ngx-table-row-highlight {
					background: $white !important;
				}
			}

			&.ngx-table-row-highlight {
				background-color: $primary-color-light !important;
				font-weight: 600;
			}

			// Wouter: Set border and background if a row's detail row is opened
			&:has(+ .ngx-table-detail-row-open):not(.prevent-primary-border) {
				border-color: $primary-color !important;
				background-color: $white !important;
			}

			.ngx-table-cell {
				padding: $table-row-padding !important;

				&.vlaio-action-cell {
					padding: $layout-spacing-xs !important;
				}
			}

			// Wouter: Only clear the padding of the direct detail row descendant
			+ .ngx-table-detail-row > .ngx-table-cell {
				padding: 0 !important;
			}
		}

		.ngx-table-detail-row-open {
			border-color: $primary-color;
			border-top: none;
		}

		td {
			&.ngx-date-table-cell,
			&.cdk-column-organizationNumber {
				// Iben: This will automatically make the cells match with the width of the header cell.
				width: 1px;
			}
		}
	}

	.cdk-header-row {
		.cdk-header-cell {
			border: none !important;
			white-space: nowrap;

			.c-sort {
				margin-left: 0.5rem;
				display: inline-block;
				vertical-align: top;
			}
		}
	}

	.ngx-table-cell {
		font-size: $table-font-size;
		line-height: $table-line-height;
		vertical-align: middle;

		border: none !important;
	}

	.c-filter__input {
		background-color: $white;
		border-color: $border-color-grey;
	}

	.vlaio-action-cell {
		width: 50px;
		max-width: 50px;
		text-align: center;

		button {
			padding: $layout-spacing-sm;
			height: 36px;
			width: 36px;

			background-color: transparent;
			border: none;

			&.vlaio-action-cell-header {
				background-color: $grey-light;
				border-radius: 50px;

				&:hover {
					background-color: darken($grey-light, 5);
				}
			}

			&.is-warning {
				color: $ui-orange;

				&:hover {
					color: darken($ui-orange, 5);
				}
			}

			&.is-danger {
				color: $ui-red;

				&:hover {
					color: darken($ui-red, 5);
				}
			}

			&.is-alert {
				color: $ui-yellow;

				&:hover {
					color: darken($ui-yellow, 5);
				}
			}

			&.is-positive {
				color: $green-30;

				&:hover {
					color: darken($green-30, 5);
				}
			}

			&:disabled {
				color: $grey-dark;

				&:hover {
					color: $grey-dark;
				}
			}
		}
	}

	.ngx-table-sorting-cell-button {
		font-family: inherit;
		font-style: inherit;
		text-transform: inherit;
		color: inherit;
		font-size: inherit;
		text-align: inherit;

		@extend .u-outline;
	}
}
