@import './variables/typography.scss';

$tag-font-size: 16px;
$tag-transition: color 0.2s ease-in-out;
$tag-trigger-size: $tag-font-size * 2;
$tag-trigger-border-radius: unset;
$tag-border-radius: 0;

$filter-font-size: 16px;
$filter-font-family: $font-family-bold;
$filter-margin-top: 8px;

$menu-item-font-family: $font-family-secondary;
$menu-item-active-font-family: $font-family-secondary;
$menu-item-font-size: 20px;

$product-grid-gap: 25px;

$font-bold-font-family: $font-family-sans;
$font-bold-font-weight: 600;
