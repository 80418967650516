.u-wysiwyg {
	.c-cookie__video,
	ul,
	ol,
	iframe {
		margin: $layout-spacing-xs 0;
	}

	ul {
		list-style-type: disc;

		li {
			font-size: $font-size;
		}
	}

	a {
		@extend .u-translation-link;
	}

	p {
		margin: $layout-spacing-sm 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		margin-top: $layout-spacing-xl;
		margin-bottom: $layout-spacing-xs;
		font-weight: bold;

		// Wouter: Strip the first element after a title from top margin to prevent unwanted whitespace
		+ * {
			margin-top: 0;
		}
	}

	h1,
	h2 {
		font-family: $font-family-sans;
	}

	h2 {
		font-size: $font-size-xlarge;
	}

	h3,
	h4,
	h5 {
		font-size: $font-size-large;
		font-family: $font-family-sans;
	}

	// Wouter: Strip the first element from top margin to prevent unwanted whitespace
	> :first-child {
		margin-top: 0;
	}
}
