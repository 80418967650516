// -------------------------------------------------------------------
// :: TOOLTIS
// -------------------------------------------------------------------

@mixin FLOW-tooltip($_direction: 'up', $_color: $white) {
	@extend %__u-box;

	&:before {
		@include FLOW-css-triangle-with-border(
			'down',
			0.8em,
			$_fill: $_color,
			$_border-color: darken($border-color, 1%)
		);
		position: absolute;
		left: calc(50% - 0.4em);
	}
}

// -------------------------------------------------------------------
// :: BASE CLASS
// -------------------------------------------------------------------
mat-tooltip-component {
	background: $white;
	color: $font-color;
}

.c-tooltip {
	@include FLOW-tooltip('down');
	color: $font-color;
	padding: 0.7rem 1rem 0.6rem;
	position: relative;
	font-size: 0.9rem;
	line-height: 1.3;
	max-width: 20rem;
}

.c-mat-tooltip {
	overflow: visible !important;
	position: relative !important;
	border: $border-simple !important;
	box-shadow: $box-shadow !important;
	background: inherit;
	color: inherit;
	padding: 0.7rem 1rem 0.6rem !important;
	font-size: 0.9rem !important;
	line-height: 1.3 !important;
	max-width: 20rem !important;

	&.c-mat-icon-tooltip {
		&:before {
			display: none;
		}
	}

	// Wouter: this matTooltip class sets the background and color of
	// the actual tooltip within the above container.
	// Wouter: this !important is needed because this style gets overridden
	// in an unpredictable order.
	.mdc-tooltip__surface {
		background-color: inherit !important;
		color: inherit !important;
	}
}

// -------------------------------------------------------------------
// :: INFO ICON WITH TOOLTIP
// -------------------------------------------------------------------

.c-info-tooltip {
	position: relative;
	display: inline-block;
}

// Icon

.c-info-tooltip {
	width: 1rem;
	height: 1rem;
	background-color: $primary-color;
	display: inline-block;
	position: relative;
	border-radius: 50%;
	cursor: help;
	text-transform: none;
}

.c-info-tooltip:after {
	content: 'i';
	font-size: $font-size-xsmall;
	font-weight: 700;
	color: $white;
	transform: translate(-70%, -50%);
	position: absolute;
	top: 50%;
	left: 55%;
}

// Tooltip

.c-info-tooltip .c-tooltip {
	opacity: 0;
	transform: translate(-50%, -100%);
	transition: opacity $animation-fast;
	pointer-events: none;
	white-space: normal;
	position: absolute;
	z-index: -1;
	left: 0.5rem;
	top: -0.5rem;

	min-width: 20rem;
}

.c-info-tooltip--down .c-tooltip {
	bottom: -0.5rem;
	top: auto;
	transform: translate(-50%, 100%);

	&:before {
		@include FLOW-css-triangle-with-border('up', 0.8em, $_fill: $white, $_border-color: darken($border-color, 1%));
		top: -0.4rem;
		bottom: auto;
	}
}

.mat-mdc-tooltip {
	background: inherit;
	color: inherit;
}

.c-info-tooltip:hover .c-tooltip {
	opacity: 1;
	z-index: $z-index-tooltips;
}
