// sass-lint:disable-all
// -------------------------------------------------------------------
// :: CONVERT PX TO REM
// -------------------------------------------------------------------

@use 'sass:math';

@function rem($_value) {
	@return #{math.div($_value, 16px)}rem;
}

@function px($value) {
	$integer: math.div($value, $value * 0 + 1) * 10px;

	@return round($integer);
}

// =============================================================================
// :: Get-breakpoint
// =============================================================================
/**
 * Retrieve the breakpoint width associated with the defined name for it. These
 * values can be found in the $grid-breakpoints map defined within the settings
 * folder.
 *
 * Params:
 * - $name: the name of the breakpoint where to retrieve the data from
 */
@function get-breakpoint($name) {
	$breakpoint: map-get($grid-breakpoints, $name);
	$width: map-get($breakpoint, width);

	@return px($width);
}

// -------------------------------------------------------------------
// :: COVERT PX TO EM
// -------------------------------------------------------------------
// Em values are always relative to the parent element so
// you might need to provide context-value (eg. font-size
// of the parent element) to get the correct value
//
// - font-size: em(16px) => 1em
// - font-size: em(16px, 24px) => 0.666em

@function em($_value, $_context: 16px) {
	@return #{math.div($_value, $_context)}em;
}

// -------------------------------------------------------------------
// :: STRIP UNIT
// -------------------------------------------------------------------
// Strips a value from its unit

@function strip-unit($_value) {
	@return math.div($_value, $_value * 0 + 1);
}

// -------------------------------------------------------------------
// :: GET FONT
// -------------------------------------------------------------------

@mixin get-font($_name, $_url, $_weight: normal, $_style: normal) {
	@font-face {
		font-family: $_name;
		src: url($_url + '.eot?v=4'); /* IE9 Compat Modes */
		src:
			url($_url + '.eot?v=4#iefix') format('embedded-opentype'),
			/* IE6-IE8 */ //url($_url +".woff2?v=4") format("woff2"), /* Super Modern Browsers */
			url($_url + '.woff?v=4') format('woff'),
			/* Pretty Modern Browsers */ url($_url + '.ttf?v=4') format('truetype'),
			/* Safari, Android, iOS */ url($_url + '.svg?v=4#icon') format('svg'); /* Legacy iOS */
		font-weight: $_weight;
		font-style: $_style;
		font-display: swap;
	}
}

// -------------------------------------------------------------------
// :: FR
// -------------------------------------------------------------------
// This function resolves the bug with 1fr in grid.
// If the content is bigger than the fraction, it will push the grid outside its boundaries.
// By setting min on 0 instead of the default auto; this is prevented.
@function fr($fraction) {
	@return minmax(0, #{$fraction}fr);
}
