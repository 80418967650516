// -------------------------------------------------------------------
// :: MIXINS
// -------------------------------------------------------------------
// Custom mixins to make standard behaviour & css more modular
//
//

// :: CLEARFLOAT
// clear float that can be applied to an element where all the childeren are floated.

@mixin clearfloat() {
	&:before,
	&:after {
		content: '';
		display: table;
	}

	&:after {
		clear: both;
	}
}

// :: SPREAD
// forces an element to take up the entire space of it's parent.

@mixin spread() {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

// :: CENTERED
// Use this to center something

@mixin centered() {
	text-align: center;
}

// -------------------------------------------------------------------
// :: UTILITY HELPERS
// -------------------------------------------------------------------
// To provide screen readers an optimal experience
// add hidden DOM-elements with additional info
//
// - http://css-tricks.com/places-its-tempting-to-use-display-none-but-dont

.is-hidden {
	width: 1px !important;
	height: 1px !important;
	margin: -1px;
	padding: 0;
	border: none;
	clip: rect(0 0 0 0);
	position: absolute;
	overflow: hidden;
}

.scrollable {
	overflow-y: scroll;
}

.v-center-outer {
	@include spread();

	.v-center {
		display: table;
		height: 100%;
		width: 100%;

		.v-center-inner {
			display: table-cell;
			vertical-align: middle;
			width: 100%;
			text-align: center;
		}
	}
}

.v-center-alt {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.h-align-right {
	text-align: right;
}

.u-clearfloat {
	@include clearfloat();
}

.u-clickable {
	cursor: pointer;
}

.u-nowrap {
	white-space: nowrap;
}

// Spacing

.u-spacing-t {
	padding-top: $layout-spacing-lg;
}

.u-spacing-t--large {
	padding-top: $layout-spacing-xl;
}

.u-margin-t--large {
	margin-top: $layout-spacing-xl !important;
}

.u-spacing-r {
	padding-right: $layout-spacing-lg;
}

.u-spacing-b {
	padding-bottom: $layout-spacing-lg;
}

.u-spacing-b--small {
	padding-bottom: $layout-spacing-xs;
}

.u-spacing-l {
	padding-left: $layout-spacing-lg;
}
