// -------------------------------------------------------------------
// :: BOX
// -------------------------------------------------------------------

%__u-box {
	background-color: $white;
	border: $border-simple;
	box-shadow: $box-shadow;
}

.u-box {
	@extend %__u-box;
	//padding: 2.5rem;

	//@include respond-at($tablet) {
	//	padding: 4rem;
	//}
	//
	//@include respond-at($desktop) {
	//	padding: 5rem;
	//}
}
