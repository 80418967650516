// -------------------------------------------------------------------
// :: COLORS
// -------------------------------------------------------------------
// See styleguide.html for more info on how colors are being used
// Imports

@import './colors_common';

// Background

$background-color: $grey-lighter;
$background-color-base: $white;
$background-color-primary-dark: $primary-color-dark;
$background-color-primary-light: $primary-color-light;
$background-color-light: $grey-96;
$background-color-black: $black;
$background-color-regular: $white-95;
$background-color-medium: $grey-46;
$background-color-light-blue: $black-light-blue;
$background-color-blue-grey: transparent; //Robbe: Not used
$background-color-white-lilac: transparent; //Robbe: Not used

// Border

$border-color: $grey-light;
$border-color-base: $blue-92;
$border-color-grey: $grey-83;
$border-color-dark-grey: $grey-46;
$border-color-dark: $grey-85;
$border-color-dark-blue: $black-dark-blue;
$border-color-primary: $primary-color;

// Font

$font-color: $grey-46; // Base
$font-color-medium: rgba($black, 0.82); // Secondary (eg. image caption)
$font-color-light: $grey; // Placeholders in forms
$font-color-light-secondary: $grey-dark;
$font-color-lighter: $grey-light; // Placeholders in forms
$font-color-disabled: $grey-light; // Disabled Text
$font-color-blue: $blue; // Link color
$font-color-blue-dark: $blue-dark; // Link color
$font-color-grey: $grey;
$font-color-grey-40: $grey-40;
$text-color-base: $grey-46;
$text-color-black: $black-lighter;
$text-color-black-title: $black-title;
$text-color-light: $white;
$text-color-medium: $blue-80;
$text-color-blue: $blue-45;
$text-color-blue-dark: $blue-48;

// Selection

$selection-color: $primary-color-selection;

// Links
$link-color: $primary-color;
$link-color-dark: $primary-color-dark;

// Buttons
$button-primary: $primary-color;
$button-primary-hover: $primary-color-hover;
$button-text: $text-color-light;
$button-text-outline: $primary-color;
$button-text-hover: $text-color-light;
$button-link-color: $link-color;

// Filters
$filter-title-border: $border-color-dark-grey;
$filter-container-background-lg: $background-color-base;
$filter-container-background-sm: $background-color-base;
$filter-label-count: $text-color-black;

// Tag
$tag-background: $background-color-regular;
$tag-border: $border-color-grey;
$tag-icon-background: transparant;
$tag-icon-hover: $primary-color;

// Pager
$pager-background: $primary-color;
$pager-background-hover: $primary-color-hover;
$pager-color: $white;
$pager-color-hover: $white;
$pager-number: $background-color-medium;
$pager-number-active: $white;
$pager-number-not-active-hover: $primary-color;

// Menu
$menu-item-active-color: $primary-color;
$menu-item-border-active-color: transparent;

// Table
$table-even-color: $grey-97;

$orange-warning: #ffc515;
