vlaio-home-hero a {
	@extend .u-outline;
	@extend .u-link__underline;
	@extend .font-bold;
}

.c-hero-about-block {
	padding: rem(90px) rem(36px) rem(40px);
	position: relative;
	background-color: $white;
	box-shadow: 0 1px 2px $white-70;
	box-sizing: border-box;

	.c-hero-about-block__logo {
		position: absolute;
		top: -54px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 234px;
		height: 110px;
		border: 2px solid $white-93;
		background: $white;

		&.c-hero-about-block__logo__right {
			right: rem(36px);

			img {
				width: 180px;
			}
		}

		@include respond-to(75rem) {
			max-width: 40%;

			&.c-hero-about-block__logo__right {
				img {
					width: 80%;
				}
			}
		}

		@include respond-to(30rem) {
			top: -25px;

			max-width: 45%;
			height: 80px;

			&.c-hero-about-block__logo__left {
				left: rem(10px);

				img {
					width: auto;
					height: 80%;
				}
			}

			&.c-hero-about-block__logo__right {
				right: rem(10px);

				img {
					width: 60%;
				}
			}
		}
	}

	img {
		width: 230px;
		height: auto;
		max-width: 100%;
		vertical-align: top;
		border: none;
	}

	h3 {
		margin-bottom: 18px;
	}

	.c-hero-about-block__ctas {
		$gap: rem(8px);

		width: calc(100% + #{$gap});
		margin: calc(-1 * #{$gap}) 0 0 calc(-1 * #{$gap});

		> * {
			margin: #{$gap} 0 0 #{$gap};
		}

		display: flex;

		@include respond-to($mobile) {
			flex-direction: column;
		}

		.c-authentication-failed__icon {
			margin-right: rem(8px);
		}
	}
}
