// Temporary fix to hide the breadcrumb bar even if empty of not configured.
.vlw__js-placeholder {
	display: none;
}

.home {
	p {
		&.vlw__primary-bar__brand__umbrella__logo__label,
		&.vlw__profile__toggle__name {
			font-family: $font-family-primary-medium !important;
		}
	}
}
