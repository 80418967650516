// Wouter: style the snackbar box itself
.c-vlaio-toast {
	cursor: pointer;

	.mdc-snackbar__surface {
		background-color: $white !important;
		max-width: 200px !important;
	}

	&.toast-negative {
		.mdc-snackbar__surface {
			border-left: 4px solid $ui-red;
		}
	}

	&.toast-positive {
		.mdc-snackbar__surface {
			border-left: 4px solid $primary-color;
		}
	}

	&.toast-info {
		.mdc-snackbar__surface {
			border-left: 4px solid $ui-blue;
		}
	}

	&.toast-warning {
		.mdc-snackbar__surface {
			border-left: 4px solid $ui-orange;
		}
	}
}

// Wouter: This is needed to add margin when the user is anonymous
// to counteract the height of the vlaio-menu
body:has(vlaio-menu) {
	.c-vlaio-toast {
		margin-top: rem(52px);
	}
}
